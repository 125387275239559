<script lang="ts">

  import type { CurrencyRatesUSD } from "./data_providers"
  import OHLCChart from "./OHLC_Chart.svelte"

  export let granularity = 86400
  export let requestedProvider = "coinbase"
  export let priceUnit: string
  export let priceInfo: CurrencyRatesUSD

</script>

<h1 class="text-xl font-semibold">DEFI</h1>
<div class="w-full flex items-center flex-wrap">
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="COMP" {granularity} {requestedProvider} {priceUnit} {priceInfo}/>
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="MKR" {granularity} {requestedProvider} {priceUnit} {priceInfo}/>
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="ZRX" {granularity} {requestedProvider} {priceUnit} {priceInfo}/>
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="TRU" {granularity} {requestedProvider} {priceUnit} {priceInfo}/>
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="LUNA" {granularity} {requestedProvider} {priceUnit} {priceInfo}/>
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="1INCH" {granularity} {requestedProvider} {priceUnit} {priceInfo}/>
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="AAVE" {granularity} {requestedProvider} {priceUnit} {priceInfo}/>  
</div>

