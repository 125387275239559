<script lang="ts">
  import OHLCChart from "./OHLC_Chart.svelte"

  export let granularity = 3600
  export let requestedProvider = "coinbase"
  export let priceUnit: string
  export let priceInfo: any
</script>

<div class="w-full flex items-center flex-wrap">
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="POLY" {granularity} {requestedProvider} {priceUnit} {priceInfo}/>
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="LRC" {granularity} {requestedProvider} {priceUnit} {priceInfo}/>
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="ENJ" {granularity} {requestedProvider} {priceUnit} {priceInfo}/>
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="PRE" {granularity} {requestedProvider} {priceUnit} {priceInfo}/>
</div>

