<script lang="ts">
  import Chart from "svelte-lightweight-charts/components/chart.svelte";
  import { CrosshairMode, IChartApi, ISeriesApi } from "lightweight-charts";
  import { CurrencyRatesUSD, getKlines, KlineSeries } from "./data_providers";
  import { cryptoMap } from "./crypto_base";

  export let width = 600;
  export let height = 400;

  export let symbol = "ETH";
  export let granularity = 300;
  export let requestedProvider = "coinbase";
  export let priceUnit = "USD";
  export let priceInfo: CurrencyRatesUSD;

  let classes = "";
  export { classes as class };

  let chartApi: IChartApi;
  let candlesSeries: ISeriesApi<"Candlestick">;
  let volumeSeries: ISeriesApi<"Histogram">;
  let klineProvider = requestedProvider;
  let klinesUSD: KlineSeries;
  let klines: KlineSeries;

  const volumeOptions = {
    color: "#26a69a",
    priceScaleId: "",
    scaleMargins: {
      top: 0.8,
      bottom: 0,
    },
  };

  let priceFormatters = {
    EUR: function (price: number) {
      return "€" + price.toPrecision(3);
    },
    USDT: function (price: number) {
      return "$" + price.toPrecision(3);
    },
    USD: function (price: number) {
      return "$" + price.toPrecision(3);
    },
  };
  /*
  const nomicsMap = {
    ETH: "eth-ethereum",
    BTC: "btc-bitcoin",
    CHZ: "chz-chiliz",
    SNX: "snx-synthetix-network-token",
    SKL: "skl-skale",
    POLY: "poly-polymath",
    MATIC: "matic-polygon",
    LINK: "link-chainlink",
    FET: "fet-fetch",
    ADA: "ada-cardano",
    TRU: "tru-truefi",
    MIR: "mirror-mirror-protocol",
    GRT: "grt-the-graph",
    RAD: "rad-radicle",
    XYO: "xyo-xyo",
    BAND: "band-band-protocol",
    RLY: "rly-rally",
    DOT: "dot-polkadot",
    MASK: "mask2-mask-network",
    NU: "nu-nucypher",
    ZRX: "zrx-0x",
    IOTX: "iotx-iotex-network",
    ANKR: "ankr-ankr",
    SOL: "sol-solana",
    ALGO: "algo-algorand",
    BTRST: "btrst-braintrust",
    AVAX: "avax-avalanche",
    WLUNA: "wlunahrc20-wrapped-luna-harmony",
    EOS: "eos-eos",
    OMG: "omg-omg-network",
    XTZ: "xtz-tezos",
  };
*/

  function build_nomics_link(): string {
    return `https://nomics.com/assets/${cryptoMap[symbol].nomics}`;
  }

  let nomicsLink = build_nomics_link();

  let options = {
    width,
    height,
    layout: {
      backgroundColor: "#000000",
      textColor: "rgba(255, 255, 255, 0.9)",
    },
    grid: {
      vertLines: {
        color: "rgba(197, 203, 206, 0.5)",
      },
      horzLines: {
        color: "rgba(197, 203, 206, 0.5)",
      },
    },
    crosshair: {
      mode: CrosshairMode.Normal,
    },
    rightPriceScale: {
      borderColor: "rgba(197, 203, 206, 0.8)",
    },
    timeScale: {
      borderColor: "rgba(197, 203, 206, 0.8)",
      timeVisible: true,
    },
    localization: {
      priceFormatter: priceFormatters[priceUnit],
    },
    handleScroll: {
      mouseWheel: false,
      pressedMouseMove: false,
      horzTouchDrag: false,
      vertTouchDrag: false,
    },
    handleScale: {
      mouseWheel: false,
    },
  };

  function set_granularity(new_value: number) {
    if (granularity !== new_value) {
      granularity = new_value;
      load_data(requestedProvider, granularity);
    }
  }

  function select_provider(p: string): string {
    if (p in cryptoMap[symbol].klines) return p;
    return cryptoMap[symbol].klines["default"];
  }

  function apply_unit(klines: KlineSeries, priceUnit: string): KlineSeries {
    if (priceUnit !== "USD") {
      return klines.scalePrice(priceInfo[priceUnit]);
    }
    return klines;
  }

  async function load_data(p: string, g: number) {
    const dataProvider = select_provider(p);
    const providerSymbol = cryptoMap[symbol].klines[dataProvider];

    klineProvider = dataProvider;
    klinesUSD = await getKlines(dataProvider, providerSymbol, g);
  }

  function set_new_data(klines: KlineSeries) {
    if (!candlesSeries) candlesSeries = chartApi.addCandlestickSeries();
    candlesSeries.setData(klines.getKlines());

    if (!volumeSeries)
      volumeSeries = chartApi.addHistogramSeries(volumeOptions);
    volumeSeries.setData(klines.getVolumes());

    chartApi.applyOptions({
      localization: { priceFormatter: priceFormatters[priceUnit] },
    });
  }

  function makeTradeLink(): string {
    const s = cryptoMap[symbol]
    if(s.trading.length > 0) {
      const [exchange, symbolName] = s.trading[0]
      if (exchange != "coinbase") return "https://not-supported.com"
      return `https://pro.coinbase.com/trade/${symbolName}`
    } else {
      return "https://not-supported.com"
    }
  }

  $: if(chartApi) load_data(requestedProvider, granularity);
  $: if(chartApi && klinesUSD) klines = apply_unit(klinesUSD, priceUnit);
  $: if(chartApi && klines) set_new_data(klines);
</script>

<div class="{classes} flex flex-col">
  <div class="flex flex-start items-center bg-black py-1 px-1 text-white">
    <a href={nomicsLink} target="_blank"
      ><h1 class="text-blue-500 font-semibold mr-2">
        {klineProvider}/{symbol}
      </h1></a
    >

    <a href={makeTradeLink()} target="_blank"
      ><h1 class="text-red-500 font-bold mr-2">trade</h1></a
    >
    <button
      class="text-sm px-2 py-1 {granularity == 300
        ? 'bg-blue-400'
        : 'bg-transparent'}"
      on:click={() => set_granularity(300)}>5m</button
    >
    <button
      class="text-sm px-2 py-1 {granularity == 900
        ? 'bg-blue-400'
        : 'bg-transparent'}"
      on:click={() => set_granularity(900)}>15m</button
    >
    <button
      class="text-sm px-2 py-1 {granularity == 3600
        ? 'bg-blue-400'
        : 'bg-transparent'}"
      on:click={() => set_granularity(3600)}>1h</button
    >
    <button
      class="text-sm px-2 py-1 {granularity == 86400
        ? 'bg-blue-400'
        : 'bg-transparent'}"
      on:click={() => set_granularity(86400)}>1d</button
    >
  </div>

  <Chart {...options} ref={(ref) => (chartApi = ref)} />
</div>
