<script lang="ts">
  import Tailwind from "./Tailwind.svelte"
  import Portfolio from "./Portfolio.svelte"
  import Defis from "./DEFI.svelte"
  import Nfts from "./NFT.svelte"
  import Bluechips from "./Bluechips.svelte"
  import OHLCChart from "./OHLC_Chart.svelte"
  import { CurrencyRatesUSD, FreeCurrencyAPI } from "./data_providers"

  const PORTFOLIO = 0
  const BLUECHIPS = 1
  const NFT = 2
  const DEFI = 3

  let showTab = PORTFOLIO
  let granularity = 3600
  let requestedProvider = "coinbase"
  let priceUnit = "USD"
  let priceInfo: CurrencyRatesUSD = undefined

  new FreeCurrencyAPI("f1e44da0-3a64-11ec-93ac-0ff674288752")
    .getRates()
    .then((rates) => (priceInfo = rates))

  function handleKeypress(e: KeyboardEvent) {
    switch (e.key) {
      case "e":
        priceUnit = "EUR"
        break
      case "u":
        priceUnit = "USD"
        break
      case "1":
        granularity = 300
        break
      case "2":
        granularity = 900
        break
      case "3":
        granularity = 3600
        break
      case "4":
        granularity = 86400
        break
      case "c":
        requestedProvider = "coinbase"
        break
      case "b":
        requestedProvider = "binance"
        break
      case "7":
        showTab = PORTFOLIO
        break
      case "8":
        showTab = BLUECHIPS
        break
      case "9":
        showTab = NFT
        break
      case "0":
        showTab = DEFI
        break
    }
  }
</script>

<Tailwind />

<svelte:window on:keypress={handleKeypress} />

<nav class="w-full flex justify-center my-1">
  <button
    on:click={() => (showTab = PORTFOLIO)}
    class="{showTab === PORTFOLIO
      ? 'bg-blue-400'
      : ''} rounded-lg text-2xl px-1 mx-1">Portfolio</button
  >
  <button
    on:click={() => (showTab = BLUECHIPS)}
    class="{showTab === BLUECHIPS
      ? 'bg-blue-400'
      : ''} rounded-lg text-2xl px-1 mx-1">Blues</button
  >
  <button
    on:click={() => (showTab = NFT)}
    class="{showTab === NFT ? 'bg-blue-400' : ''} rounded-lg text-2xl px-1 mx-1"
    >NFT</button
  >
  <button
    on:click={() => (showTab = DEFI)}
    class="{showTab === DEFI
      ? 'bg-blue-400'
      : ''} rounded-lg text-2xl px-1 mx-1">DEFI</button
  >
</nav>

<div class="w-full flex justify-center items-center my-1">
  <div class="flex rounded-lg bg-gray-200 mx-2">
    <button
      on:click={() => (requestedProvider = "coinbase")}
      class="{requestedProvider === 'coinbase'
        ? 'bg-blue-400'
        : ''} font-semibold py-1 rounded-l-lg px-1">CB</button
    >
    <button
      on:click={() => (requestedProvider = "binance")}
      class="{requestedProvider === 'binance'
        ? 'bg-blue-400'
        : ''} font-semibold py-1 rounded-r-lg px-1">BNC</button
    >
    <button
      on:click={() => (requestedProvider = "kucoin")}
      class="{requestedProvider === 'kucoin'
        ? 'bg-blue-400'
        : ''} font-semibold py-1 rounded-r-lg px-1">KuC</button
    >
  </div>

  <div class="flex rounded-lg bg-gray-200 mx-2">
    <button
      class="text-sm font-bold py-1 rounded-l-lg px-1 {granularity == 300
        ? 'bg-blue-400'
        : 'bg-transparent'}"
      on:click={() => (granularity = 300)}
    >
      5m
    </button>
    <button
      class="text-sm font-bold py-1 px-1 {granularity == 900
        ? 'bg-blue-400'
        : 'bg-transparent'}"
      on:click={() => (granularity = 900)}
    >
      15m
    </button>
    <button
      class="text-sm font-bold py-1 px-1 {granularity == 3600
        ? 'bg-blue-400'
        : 'bg-transparent'}"
      on:click={() => (granularity = 3600)}
    >
      1h
    </button>
    <button
      class="text-sm font-bold py-1 rounded-r-lg px-1 {granularity == 86400
        ? 'bg-blue-400'
        : 'bg-transparent'}"
      on:click={() => (granularity = 86400)}
    >
      1d
    </button>
  </div>

  {#if priceInfo}
    <div class="flex rounded-lg bg-gray-200 mx-2">
      <button
        on:click={() => (priceUnit = "USD")}
        class="text-sm font-semibold py-1 rounded-l-lg px-1 {priceUnit === 'USD'
          ? 'bg-blue-400'
          : 'bg-transparent'}">USD</button
      >
      <button
        on:click={() => (priceUnit = "EUR")}
        class="text-sm font-semibold py-1 rounded-r-lg px-1 {priceUnit === 'EUR'
          ? 'bg-blue-400'
          : 'bg-transparent'}">EUR</button
      >
    </div>
  {/if}
</div>

{#if showTab === PORTFOLIO}
  <Portfolio {requestedProvider} {granularity} {priceUnit} {priceInfo} />
{:else if showTab === BLUECHIPS}
  <Bluechips {requestedProvider} {granularity} {priceUnit} {priceInfo} />
{:else if showTab === NFT}
  <Nfts {requestedProvider} {granularity} {priceUnit} {priceInfo} />
{:else}
  <Defis {requestedProvider} {granularity} {priceUnit} {priceInfo} />
{/if}

<div class="w-full flex justify-center">
  <h1 class="uppercase text-4xl">REFERENCES</h1>
</div>

<div class="w-full flex items-center flex-wrap">
  <OHLCChart
    class="px-2 py-2"
    width={350}
    height={350}
    symbol="BTC"
    {granularity}
    {requestedProvider}
    {priceUnit}
    {priceInfo}
  />
  <OHLCChart
    class="px-2 py-2"
    width={350}
    height={350}
    symbol="ETH"
    {granularity}
    {requestedProvider}
    {priceUnit}
    {priceInfo}
  />
</div>
