export class OHLCV {
    constructor(time, open, high, low, close, volume) {
        this.time = time;
        this.open = open;
        this.high = high;
        this.low = low;
        this.close = close;
        this.volume = volume;
    }
}
export class KlineSeries {
    constructor(klines) {
        this.klines = klines;
    }
    getVolumes() {
        let ks = this.klines;
        let last_volume = 0.0;
        let result = [];
        for (var i = 0; i < ks.length; i++) {
            const ohlcv = ks[i];
            const color = ohlcv.volume < last_volume ? "#ff2400a0" : "#03ac13a0";
            result.push({
                time: ohlcv.time,
                value: ohlcv.volume,
                color,
            });
            last_volume = ohlcv.volume;
        }
        return result;
    }
    getKlines() {
        return this.klines;
    }
    scalePrice(ratio) {
        const klines = this.klines.map((ohlc) => new OHLCV(ohlc.time, ohlc.open * ratio, ohlc.high * ratio, ohlc.low * ratio, ohlc.close * ratio, ohlc.volume));
        return new KlineSeries(klines);
    }
}
export class KuCoin {
    static async getKlines(symbol, granularitySeconds) {
        const klineType = this.convertGranularity(granularitySeconds);
        const response = await fetch(`https://api.kucoin.com/api/v1/market/candles?symbol=${symbol}&type=${klineType}`, { credentials: "omit" });
        if (response.ok) {
            const fetchData = await response.json();
            return new KlineSeries(fetchData['data'].map(d => new OHLCV(parseFloat(d[0]), parseFloat(d[1]), parseFloat(d[3]), parseFloat(d[4]), parseFloat(d[2]), parseFloat(d[5]))));
        }
        else {
            return undefined;
        }
    }
    static convertGranularity(granularitySeconds) {
        switch (granularitySeconds) {
            case 60: return "1min";
            case 300: return "5min";
            case 900: return "15min";
            case 3600: return "1hour";
            case 86400: return "1day";
            default: return undefined;
        }
    }
}
export class Coinbase {
    static async getKlines(symbol, granularitySeconds) {
        // example: https://api.exchange.coinbase.com/products/POLY-USDT/candles?granularity=60
        const response = await fetch(`https://api.exchange.coinbase.com/products/${symbol}/candles?granularity=${granularitySeconds}`);
        if (response.ok) {
            const fetch_data = await response.json();
            fetch_data.sort((a, b) => a[0] - b[0]);
            return new KlineSeries(fetch_data.map(d => new OHLCV(d[0], d[3], d[2], d[1], d[4], d[5])));
        }
        else {
            return undefined;
        }
    }
}
export class Binance {
    static async getKlines(symbol, granularitySeconds) {
        // example: https://api.binance.com/api/v3/klines?symbol=POLYUSDT&interval=5m
        const bInt = Binance.convertGranularity(granularitySeconds);
        const response = await fetch(`https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=${bInt}`);
        if (response.ok) {
            const fetch_data = await response.json();
            fetch_data.sort((a, b) => a[0] - b[0]);
            // docs: https://binance-docs.github.io/apidocs/spot/en/#kline-candlestick-data
            return new KlineSeries(fetch_data.map(d => new OHLCV(d[0] / 1000.0, parseFloat(d[1]), parseFloat(d[2]), parseFloat(d[3]), parseFloat(d[4]), parseFloat(d[5]))));
        }
        else {
            return undefined;
        }
    }
    static convertGranularity(granularitySeconds) {
        switch (granularitySeconds) {
            case 60: return "1m";
            case 300: return "5m";
            case 900: return "15m";
            case 3600: return "1h";
            case 86400: return "1d";
            default: return undefined;
        }
    }
    static convertSymbol(symbol) {
        const [crypto, second] = symbol.split('-');
        return `${crypto}${second}`;
    }
}
export async function getKlines(provider, symbol, granularitySeconds) {
    switch (provider) {
        case 'binance': return Binance.getKlines(symbol, granularitySeconds);
        case 'coinbase': return Coinbase.getKlines(symbol, granularitySeconds);
        case 'kucoin': return KuCoin.getKlines(symbol, granularitySeconds);
        default: return undefined;
    }
}
export class FreeCurrencyAPI {
    constructor(key) {
        this.key = key;
    }
    async getRates() {
        const response = await fetch(`https://freecurrencyapi.net/api/v2/latest?apikey=${this.key}`);
        if (response.ok) {
            const data = await response.json();
            return data["data"];
        }
        else {
            return undefined;
        }
    }
}
