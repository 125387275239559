<script lang="ts">
import type { CurrencyRatesUSD } from "./data_providers";

  import OHLCChart from "./OHLC_Chart.svelte"

  export let granularity = 86400
  export let requestedProvider = "coinbase"
  export let priceUnit: string
  export let priceInfo: CurrencyRatesUSD

</script>

<div class="w-full flex items-center flex-wrap">
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="DOT" granularity={granularity} {requestedProvider} {priceUnit} {priceInfo}/>
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="LINK" granularity={granularity} {requestedProvider} {priceUnit} {priceInfo}/>
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="MATIC" granularity={granularity} {requestedProvider} {priceUnit} {priceInfo}/>
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="GRT" granularity={granularity} {requestedProvider} {priceUnit} {priceInfo}/>
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="SOL" granularity={granularity} {requestedProvider} {priceUnit} {priceInfo}/>
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="XTZ" granularity={granularity} {requestedProvider} {priceUnit} {priceInfo}/>
  <OHLCChart class="px-2 py-2" width={350} height={350} symbol="ADA" granularity={granularity} {requestedProvider} {priceUnit} {priceInfo}/>
</div>

