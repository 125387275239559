<script lang="ts">
  import type { CurrencyRatesUSD } from "./data_providers";
  
    import OHLCChart from "./OHLC_Chart.svelte"
  
    export let granularity = 86400
    export let requestedProvider = "coinbase"
    export let priceUnit: string
    export let priceInfo: CurrencyRatesUSD
  </script>
  
 
  <h1 class="text-xl font-semibold">NFTs</h1>
  <div class="w-full flex items-center flex-wrap">
    <!-- NFT -->
    <OHLCChart class="px-2 py-2" width={350} height={350} symbol="UOS" {granularity} {requestedProvider} {priceUnit} {priceInfo}/>
    <OHLCChart class="px-2 py-2" width={350} height={350} symbol="SOUL" {granularity} {requestedProvider} {priceUnit} {priceInfo}/>

    <OHLCChart class="px-2 py-2" width={350} height={350} symbol="AAVE" {granularity} {requestedProvider} {priceUnit} {priceInfo}/>
    <OHLCChart class="px-2 py-2" width={350} height={350} symbol="RLY" {granularity} {requestedProvider} {priceUnit} {priceInfo}/>
    <OHLCChart class="px-2 py-2" width={350} height={350} symbol="CHZ" {granularity} {requestedProvider} {priceUnit} {priceInfo}/>
    <OHLCChart class="px-2 py-2" width={350} height={350} symbol="AVAX" {granularity} {requestedProvider} {priceUnit} {priceInfo}/>
  </div>
  
  