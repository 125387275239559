export const cryptoMap = {
    MKR: {
        priceUnit: "USD",
        nomics: "mkr-maker",
        klines: {
            binance: "MKRUSDT",
            default: "binance"
        },
        trading: []
    },
    COMP: {
        priceUnit: "USD",
        nomics: "comp-compound",
        klines: {
            binance: "COMPUSDT",
            default: "binance"
        },
        trading: []
    },
    UOS: {
        priceUnit: "USD",
        nomics: "uos-ultra",
        klines: {
            kucoin: "UOS-USDT",
            default: "kucoin"
        },
        trading: []
    },
    SOUL: {
        priceUnit: "USD",
        nomics: "soul-phantasma",
        klines: {
            kucoin: "SOUL-USDT",
            default: "kucoin"
        },
        trading: []
    },
    ENJ: {
        klines: {
            "binance": "ENJUSDT",
            default: "binance"
        },
        trading: [],
        nomics: "enj-enjin-coin",
        priceUnit: "USD"
    },
    PRE: {
        klines: {
            kucoin: "ENJ-USDT",
            default: "kucoin"
        },
        trading: [],
        nomics: "enj-enjin",
        priceUnit: "USD"
    },
    LRC: {
        klines: {
            "binance": "LRCUSDT",
            default: "binance"
        },
        trading: [],
        nomics: "lrc-loopring",
        priceUnit: "USD"
    },
    BAT: {
        klines: {
            "coinbase": "BAT-USD",
            "binance": "BATUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "BAT-EUR"]
        ],
        nomics: "bat-basic-attention-token",
        priceUnit: "USD"
    },
    BTRST: {
        klines: {
            "coinbase": "BTRST-USD",
            "default": "coinbase"
        },
        trading: [
            ["coinbase", "BTRST-EUR"]
        ],
        nomics: "btrst-braintrust",
        priceUnit: "USD"
    },
    POLY: {
        klines: {
            "coinbase": "POLY-USDT",
            "binance": "POLYUSDT",
            "default": "coinbase"
        },
        trading: [
            ["coinbase", "POLY-USDT"]
        ],
        nomics: "poly-polymath",
        priceUnit: "USD"
    },
    SKL: {
        klines: {
            "coinbase": "SKL-USD",
            "binance": "SKLUSDT",
            "default": "coinbase"
        },
        trading: [
            ["coinbase", "SKL-EUR"]
        ],
        nomics: "skl-skale",
        priceUnit: "USD"
    },
    BTC: {
        klines: {
            "coinbase": "BTC-USDT",
            "binance": "BTCUSDT",
            "default": "coinbase"
        },
        trading: [
            ["coinbase", "BTC-EUR"]
        ],
        nomics: "btc-bitcoin",
        priceUnit: "USD"
    },
    ETH: {
        klines: {
            "coinbase": "ETH-USDT",
            "binance": "ETHUSDT",
            "default": "coinbase"
        },
        trading: [
            ["coinbase", "ETH-EUR"]
        ],
        nomics: "eth-ethereum",
        priceUnit: "USD"
    },
    REQ: {
        klines: {
            "coinbase": "REQ-USDT",
            "binance": "REQUSDT",
            "default": "coinbase"
        },
        trading: [
            ["coinbase", "REQ-EUR"]
        ],
        nomics: "req-request-network",
        priceUnit: "USD"
    },
    DOT: {
        klines: {
            "coinbase": "DOT-USDT",
            "binance": "DOTUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "DOT-EUR"]
        ],
        nomics: "dot-polkadot",
        priceUnit: "USD"
    },
    LINK: {
        klines: {
            "coinbase": "LINK-USD",
            "binance": "LINKUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "LINK-EUR"]
        ],
        nomics: "link-chainlink",
        priceUnit: "USD"
    },
    MATIC: {
        klines: {
            "coinbase": "MATIC-USD",
            "binance": "MATICUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "MATIC-EUR"]
        ],
        nomics: "matic-polygon",
        priceUnit: "USD"
    },
    GRT: {
        klines: {
            "coinbase": "GRT-USD",
            "binance": "GRTUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "GRT-EUR"]
        ],
        nomics: "grt-the-graph",
        priceUnit: "USD"
    },
    SOL: {
        klines: {
            "coinbase": "SOL-USDT",
            "binance": "SOLUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "SOL-EUR"]
        ],
        nomics: "sol-solana",
        priceUnit: "USD"
    },
    XTZ: {
        klines: {
            "coinbase": "XTZ-USD",
            "binance": "XTZUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "XTZ-EUR"]
        ],
        nomics: "xtz-tezos",
        priceUnit: "USD"
    },
    ADA: {
        klines: {
            "coinbase": "ADA-USD",
            "binance": "ADAUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "ADA-EUR"]
        ],
        nomics: "ada-cardano",
        priceUnit: "USD"
    },
    XYO: {
        klines: {
            "coinbase": "XYO-USD",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "XYO-EUR"]
        ],
        nomics: "xyo-xyo",
        priceUnit: "USD"
    },
    MASK: {
        klines: {
            "coinbase": "MASK-USD",
            "binance": "MASKUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "MASK-EUR"]
        ],
        nomics: "mask2-mask-network",
        priceUnit: "USD"
    },
    RAD: {
        klines: {
            "coinbase": "RAD-USD",
            "binance": "RADUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "RAD-EUR"]
        ],
        nomics: "rad-radicle",
        priceUnit: "USD"
    },
    FET: {
        klines: {
            "coinbase": "FET-USD",
            "binance": "FETUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "FET-EUR"]
        ],
        nomics: "fet-fetch",
        priceUnit: "USD"
    },
    IOTX: {
        klines: {
            "coinbase": "IOTX-USD",
            "binance": "IOTXUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "IOTX-EUR"]
        ],
        nomics: "iotx-iotex-network",
        priceUnit: "USD"
    },
    OMG: {
        klines: {
            "coinbase": "OMG-USD",
            "binance": "OMGUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "OMG-EUR"]
        ],
        nomics: "omg-omg-network",
        priceUnit: "USD"
    },
    BAND: {
        klines: {
            "coinbase": "BAND-USD",
            "binance": "BANDUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "BAND-EUR"]
        ],
        nomics: "band-band-protocol",
        priceUnit: "USD"
    },
    MIR: {
        klines: {
            "coinbase": "MIR-USD",
            "binance": "MIRUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "MIR-EUR"]
        ],
        nomics: "mirror-mirror-protocol",
        priceUnit: "USD"
    },
    NKN: {
        klines: {
            "coinbase": "NKN-USD",
            "binance": "NKNUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "NKN-EUR"]
        ],
        nomics: "nkn-nkn",
        priceUnit: "USD"
    },
    ANKR: {
        klines: {
            "coinbase": "ANKR-USD",
            "binance": "ANKRUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "ANKR-EUR"]
        ],
        nomics: "ankr-ankr",
        priceUnit: "USD"
    },
    NU: {
        klines: {
            "coinbase": "NU-USD",
            "binance": "NUUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "NU-EUR"]
        ],
        nomics: "",
        priceUnit: "USD"
    },
    ALGO: {
        klines: {
            "coinbase": "ALGO-USD",
            "binance": "ALGOUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "ALGO-EUR"]
        ],
        nomics: "algo-algorand",
        priceUnit: "USD"
    },
    EOS: {
        klines: {
            "coinbase": "EOS-USD",
            "binance": "EOSUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "EOS-EUR"]
        ],
        nomics: "eos-eos",
        priceUnit: "USD"
    },
    ZRX: {
        klines: {
            "coinbase": "ZRX-USD",
            "binance": "ZRXUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "ZRX-EUR"]
        ],
        nomics: "zrx-0x",
        priceUnit: "USD"
    },
    TRU: {
        klines: {
            "coinbase": "TRU-USD",
            "binance": "TRUUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "TRU-EUR"]
        ],
        nomics: "tru-truefi",
        priceUnit: "USD"
    },
    LUNA: {
        klines: {
            "coinbase": "WLUNA-USD",
            "binance": "LUNAUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "WLUNA-EUR"]
        ],
        nomics: "wlunahrc20-wrapped-luna-harmony",
        priceUnit: "USD"
    },
    RLY: {
        klines: {
            "coinbase": "RLY-USD",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "RLY-EUR"]
        ],
        nomics: "rly-rally",
        priceUnit: "USD"
    },
    CHZ: {
        klines: {
            "coinbase": "CHZ-USD",
            "binance": "CHZUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "CHZ-EUR"]
        ],
        nomics: "chz-chiliz",
        priceUnit: "USD"
    },
    AVAX: {
        klines: {
            "coinbase": "AVAX-USD",
            "binance": "AVAXUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "AVAX-EUR"]
        ],
        nomics: "avax-avalanche",
        priceUnit: "USD"
    },
    "1INCH": {
        klines: {
            "coinbase": "1INCH-USD",
            "binance": "1INCHUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "1INCH-EUR"]
        ],
        nomics: "1inch-1inch",
        priceUnit: "USD"
    },
    XLM: {
        klines: {
            "coinbase": "XLM-USD",
            "binance": "XLMUSDT",
            default: "coinbase"
        },
        trading: [
            ["coinbase", "XLM-EUR"]
        ],
        nomics: "xlm-stellar",
        priceUnit: "USD"
    },
    AAVE: {
        klines: {
            "coinbase": "AAVE-USD",
            "binance": "AAVEUSDT",
            "default": "coinbase"
        },
        trading: [
            ["coinbase", "AAVE-EUR"]
        ],
        nomics: "aave-aave",
        priceUnit: "USD"
    }
};
